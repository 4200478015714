// Colors
$red: #53292a;
$ligth-brown: #a36c5b;
$green: #4caf50;
$blue: #2196f3;
$brown: rgb(160, 47, 2);
$black: rgb(20,20,20);
$dark: rgb(65,65,65);
$white: #d8d4d4;
$grey: #D9D9D9;

$smaller: 1150px;

html {
  scroll-behavior: smooth;
}


.project-section {
  background-color: $red;
  width: 100vw; min-height: 100vh;
  box-sizing: border-box;
  & .project-slider {
    display: flex; //flex-wrap: nowrap;
    flex-direction: column;
    transition: all 500ms ease-out 0s;
    & .project-item {
      padding: 25px;
      position: relative;
      display: flex;
      justify-content: center; align-items: center;
      flex-shrink: 0; flex-grow: 0;
      width: 100vw; min-height: 100vh;
      box-sizing: border-box;
      @media (max-width: $smaller) {
        align-items: flex-start;
      }
      & .inner-item {
        position: relative;
        width: 1100px;
        height: auto;
        margin: auto;
        @media (max-width: $smaller) {
          width: 300px; height: auto;
          margin: 100px auto;
        }
        & .cover-img {
          width: 550px; height: auto;
          position: relative; z-index: 1;
          vertical-align: middle; object-fit: cover;
          box-shadow: 0 5px 10px rgba(0, 0, 29, 0.25);
          margin-left: 50%;
          @media (max-width: $smaller) {
            width: 100%;
            margin-left: 0;
          }
        }
        & .overlay {
          position: absolute; z-index: 3;
          inset: 0 50% 0 0; padding: 25px;
          background: rgba(0, 0, 0, 0.5);
          display: flex; flex-direction: column;
          justify-content: center;
          @media (max-width: $smaller) {
            position: relative;
            background: none;
          }
          & .title {
            font-size: xx-large;
            line-height: 1;
            @media (max-width: $smaller) {
              font-size: large;
            }
          }
          & .description {
            font-size: large;
            @media (max-width: $smaller) {
              font-size: small;
            }
          }
          & .price {
            font-size: medium;
            @media (max-width: $smaller) {
              font-size: smaller;
            }
          }
          & .toolbar {
            position: absolute;
            bottom: 15px;
            & .nav-btn {
              text-align: start;
              line-height: 1;
            }
          }
        }
      }
    }
  }
  & .slider-btn {
    position: absolute;
    top: 50%;
    @media (max-width: $smaller) {
      top: 32.5%;
    }
    &.left {
      left: 50px;
      @media (max-width: 750px) {
        left: 20px;
      }
      @media (max-width: $smaller) {
        left: 10px;
      }
    }
    &.right {
      right: 50px;
      @media (max-width: 750px) {
        right: 20px;
      }
      @media (max-width: $smaller) {
        right: 10px;
      }
    }
  }
}

.icon-down {
  background-color: $white;
  position: absolute;
  left: 25px;
  bottom: 25px;
  width: 50px; height: 50px;
  padding: 0;
  color: $red;
}
