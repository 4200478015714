.video-page-hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 75px;
  width: 100%;
  & img {
    width: 100%;
    height: auto;
    @media (min-width: 1600px) {
      max-width: 1600px;
    }
  }
}
