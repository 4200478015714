// Colors
$red: #53292a;
$ligth-brown: #a36c5b;
$green: #4caf50;
$blue: #2196f3;
$brown: rgb(160, 47, 2);
$black: rgb(20,20,20);
$dark: rgb(65,65,65);
$white: #d8d4d4;
$grey: #D9D9D9;

$smaller: 992px;
$mobile: 1200px;

.event-details-page {
  width: 100wv; min-height: 100vh;
  & .inner {
    display: flex; flex-direction: row;
    max-width: 1600px; margin: auto;
    column-gap: 50px;
    align-items: center;
    @media (max-width: 1600px) {
      max-width: 1200px;
    }
    @media (max-width: $mobile) {
      max-width: 100%;
    }
    @media (max-width: $smaller) {
      max-width: 100%;
      flex-direction: column;
      align-items: center;
    }
    & .event-img {
      width: 500px; height: 500px;
      box-shadow: 0 5px 10px rgba(0, 0, 29, 0.25);
      @media (max-width: 1600px) {
        width: 400px; height: 400px;
      }
      @media (max-width: $mobile) {
        width: 300px; height: 300px;
      }
      @media (max-width: $smaller) {
        width: 100%; height: 100%;
      }
    }
    & .name {
      font-size: xx-large;
      letter-spacing: 1px;
    }
    & .introduction {
      font-size: x-large;
      letter-spacing: 1px;
    }
    & .description {
      font-size: large;
      letter-spacing: 1px;
    }
    & .time, & .location {
      font-size: large;
      letter-spacing: 2px;
      margin: 10px 0;
    }
    & .rs-btn {
      min-width: 300px;
      font-size: medium;
      margin-top: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 29, 0.25);
    }
  }
}
