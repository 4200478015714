.privacy-policy-page {
  padding: 125px 0;
  width: 100%;
  max-width: 1600px;
  margin: auto;
  @media (max-width: 1600px) {
    max-width: 1200px;
  }
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 125px 20px;
  }
  & .title {
    margin: 30px 0;
  }
}
