.responsive-container {
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 20px;
  }
}
.about-txt {
  text-align: justify;
}
