.text-white {
  color: #d8d4d4;
}

.text-light-brown {
  color: #a36c5b;
}

.text-green {
  color: #4caf50;
}

.text-blue {
  color: #2196f3 !important;
}

.text-black {
  color: #000 !important;
}

.text-brown {
  color: rgb(160, 47, 2)!important;
}

.text-bordeaux {
  color: #53292a;
}

.text-black-45 {
  color: rgba(0, 0, 0, 0.45);
}

.text-black-70 {
  color: rgba(0, 0, 0, 0.7);
}

.bg-black-02 {
  background-color: rgba(0, 0, 0, 0.02);
}

.bg-grey {
  background-color: #D9D9D9;
}

.bg-bordeaux {
  background-color: #53292a;
}
