@import 'override';
@import 'utility';
@import 'utility_colors';

@font-face {
  font-family: merriweather-light;
  src: url(../components/fonts/Merriweather/Merriweather-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: merriweather;
  src: url(../components/fonts/Merriweather/Merriweather-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: merriweather-bold;
  src: url(../components/fonts/Merriweather/Merriweather-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: merriweather-black;
  src: url(../components/fonts/Merriweather/Merriweather-Black.ttf);
  font-weight: 900;
}

body {
  margin: 0;
  font-family: merriweather, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fcfcfc;
}

#page-wrap {
  overflow-x: hidden;
}

html,
body,
#root,
#root>div {
  height: 100%;
}

#navbar {
  position: fixed;
  padding: 10px 25px;
  top: 0;
  left: 0;
  right: 0;
}

h1,
h2,
h3 {
  font-family: Merriweather;
}

h1 {
  font-size: 5rem;
  font-family: merriweather-bold;
  letter-spacing: 8%;
}

h2 {
  font-size: 3rem;
  font-family: merriweather-light;
}

#logo {
  line-height: 2.5rem;
}

h3 {
  font-size: 2rem;
  font-family: merriweather;
}

#small-logo {
  line-height: 1.25rem;
  border-radius: 5px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0 20px;

  &:hover {
    color: #d8d4d4;
  }
}

img {
  object-fit: cover;
}

p {
  font-size: 1rem;
  line-height: 2rem;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
  }

  &::-webkit-scrollbar-thumb {
    background: darken(#ddd, 20%);
  }
}

.img-fullsize {
  * {
    width: auto !important;
    height: auto !important;
  }
}

.chat-top,
.chat-middle,
.chat-bottom {
  margin: 0;
  padding: 0;
}

.chat-top,
.chat-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

$chat-t: 75px;
$chat-b: 65px;

.chat-top {
  height: $chat-t;
}

.chat-bottom {
  height: $chat-b;
}

.chat-middle {
  height: calc(100% - #{$chat-t} - #{$chat-b});
}

.msg-list {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  list-style-type: none;

  li {
    &:last-child {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.animate-blink {
  animation: blink normal 1.5s infinite ease-in-out;
  color: red !important;

  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
}

#home-hero {
  background: url('../assets/paolopiatto.jpg');
  background-position: center;

  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
}

#hero-img {
  width: 112.5%;
  height: 70vh;
  position: absolute;
  top: 7.5%;
  bottom: 0;
  left: -12.5%;
  right: 0;
  z-index: 1;
  object-fit: cover;
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.hide-scrollbar {
  &::-webkit-scrollbar {display: none;}
  -ms-overflow-style: none;
  scrollbar-width: none;
  & .modal-image {
    object-fit: contain;
  }
}
