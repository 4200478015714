$indent_1: 5px;
$indent_2: 10px;
$indent_3: 15px;
$indent_4: 25px;
$indent_5: 50px;

$font_1: 1rem;
$font_1_5: 1.5rem;
$font_2: 2rem;
$font_3: 3rem;
$font_4: 4rem;
$font_5: 5rem;

.lh-1-5 {
  line-height: $font_1_5;
}

.ml-5 {
  margin-left: $indent_5;
}

.fs-1 {
  font-size: $font_1;
}

.fs-1-5 {
  font-size: $font_1_5;
}

.fs-2 {
  font-size: $font_2;
}

.fs-3 {
  font-size: $font_3;
}

.fs-4 {
  font-size: $font_4;
}

.fs-5 {
  font-size: $font_5;
}

.fl-l {
  float: left;
}

.fl-r {
  float: right;
}

.link-unstyled {

  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    text-decoration: none;
  }
}

.br-5 {
  border-radius: 5px;
}

.shadow {
  box-shadow: 0 5px 10px rgb(0 0 29 / 25%);
}

.transition {
  transition: all .3s ease-in-out;
}

.text-2 {
  font-size: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-disappear {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.b-box {
  box-sizing: border-box;
}

.width-250 {
  width: 250px;
}

.width-200 {
  width: 200px;
}

.max-content {
  width: max-content;
}

.h-mc {
  height: max-content;
}

.height-200 {
  height: 200px;
}

.height-220 {
  height: 220px;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}

.vh-90 {
  height: 90vh;
}

.vh-75 {
  height: 75vh;
}

.w-100 {
  width: 100%;
}

.vw-100 {
  width: 100vw;
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}

.w-auto {
  width: auto;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.h-auto {
  height: auto;
}

.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-up {
  flex-shrink: 0;
  flex-grow: 0;
}

.align-top {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-content-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.padded {
  padding: 10px;
}

.padded-large {
  padding: 25px;
}

.p-50 {
  padding: 50px;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.relative {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.ws-nowrap {
  white-space: nowrap;
}

.br-circle {
  border-radius: 50%;
}

.font-bolder {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.letter-space-5 {
  letter-spacing: 5px;
}

.italic {
  font-style: italic;
}

.text-small {
  font-size: .75rem;
}

.word-break-all {
  word-break: break-all;
}

.text-md {
  font-size: 1.5rem;
}

.font-huge {
  font-size: 100px;
}

.contain-scroll {
  overscroll-behavior: contain;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.mt-page {
  margin-top: 150px;
}

.mt-1 {
  margin-top: $indent_1;
}

.mt-2 {
  margin-top: $indent_2;
}

.mt-3 {
  margin-top: $indent_3;
}

.mt-4 {
  margin-top: $indent_4;
}

.mt-5 {
  margin-top: $indent_5;
}

.mb-1 {
  margin-bottom: $indent_1;
}

.mb-2 {
  margin-bottom: $indent_2;
}

.mb-3 {
  margin-bottom: $indent_3;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-1 {
  margin-left: $indent_1;
}

.ml-2 {
  margin-left: $indent_2;
}

.ml-3 {
  margin-left: $indent_3;
}

.mr-1 {
  margin-right: $indent_1;
}

.mr-2 {
  margin-right: $indent_2;
}

.mr-3 {
  margin-right: $indent_3;
}

.pt-1 {
  padding-top: $indent_1;
}

.pt-2 {
  padding-top: $indent_2;
}

.pt-3 {
  padding-top: $indent_3;
}

.p-page {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pb-1 {
  padding-bottom: $indent_1;
}

.pb-2 {
  padding-bottom: $indent_2;
}

.pb-3 {
  padding-bottom: $indent_3;
}

.border-b-1 {
  border-bottom: .5px solid rgb(160, 160, 160);
}

.op-7 {
  opacity: .7;
}

.rg-10 {
  row-gap: 10px;
}

.cg-1 {
  column-gap: $indent_1;
}

.cg-2 {
  column-gap: $indent_2;
}

.cg-3 {
  column-gap: $indent_3;
}

.cg-4 {
  column-gap: $indent_4;
}

.cg-5 {
  column-gap: $indent_5;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  position: fixed;
  bottom: 0; left: 0; right: 0; top: calc(100vh - 39px);
}
