$red: #53292a;
$ligth-brown: #a36c5b;
$green: #4caf50;
$blue: #2196f3;
$brown: rgb(160, 47, 2);
$black: rgb(20,20,20);

.purchase-modal {
  & .modal-header {
    & .title {
      color: $black;
      font-size: xx-large;
      text-align: center;
    }
  }
  & .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .title {
      margin-top: 15px;
      color: $black;
      font-size: large;
      text-align: center;
      line-height: 1;
    }
    & .introduction {
      margin: 5px 0 25px;
      font-size: medium;
      text-align: center;
      line-height: 1.25;
    }
    & .make-order-con {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      & .instructions {
        margin: 0 0 25px;
        font-size: medium;
        text-align: center;
        line-height: 1.5;
        color: $black;
      }
      & .form-con {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
      }
    }
  }
}
