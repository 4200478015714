$mobile: 992px;
$small: 430px;
.about-page-info {
  @media (max-width: $mobile) {
    padding: 25px;
  }
  & .about-title {
    font-size: xx-large;
  }
  & .about-content {
    text-align: justify;
    max-width: 90%;
    @media (max-width: $mobile) {
      max-width: 100%;
    }
    & .extra-info {
      text-align: start;
      @media (max-width: $small) {
        font-size: small;
      }
    }
  }
}
